<template>
  <div class="copyrightParent">
    <a
      class="topRightCloseButton"
      v-if="!$store.getters.isAuthenticated"
      v-on:click="goToLogin()"
      >X</a
    >
    <div class="copyrightWrapper">
      <h1>{{ $t("copyright.copyright") }}</h1>
      <div v-html="$t('copyright.text')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  data() {
    return {};
  },
  methods: {
    goToLogin() {
      this.$router.push(this.$i18nRoute({name: "Login"}));
    },
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.copyrightParent {
  height: 100%;
  overflow: auto;
}
.copyrightWrapper {
  padding: 2em;
}
</style>